var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "bg-half bg-primary d-table w-100",
    staticStyle: {
      "padding": "30px 0 0px"
    }
  }, [_c('div', {
    staticClass: "text-white text-right pr-4 btn-hai"
  }, [_c('p', {
    staticClass: "p-lang"
  }, [_vm._v(" " + _vm._s(_vm.$t("lang")) + " "), _c('span', {
    staticClass: "lang-bg ml-2"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('ms');
      }
    }
  }, [_vm._v(" BM ")]), _vm._v(" | "), _c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.changeLocale('en');
      }
    }
  }, [_vm._v(" ENG ")])])])]), _c('img', {
    staticClass: "img-center signup-logo",
    staticStyle: {
      "width": "400px"
    },
    attrs: {
      "src": "/images/logo/AWARIS_Logo_BW.png",
      "alt": ""
    }
  })]), _c('section', {
    staticClass: "bg-home d-flex align-items-center",
    staticStyle: {
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "padding-right": "100px",
      "padding-left": "100px",
      "height": "inherit"
    }
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-12"
  }, [_c('div', {
    staticClass: "card login_page shadow border-0"
  }, [_c('div', {
    staticClass: "card-body-signup"
  }, [_c('h4', {
    staticClass: "card-title text-center"
  }, [_vm._v(_vm._s(_vm.$t("signup.reg")))]), _c('form', {
    staticClass: "login-form mt-4",
    on: {
      "submit": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onSubmit($event);
      }
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.fullname.$error && _vm.$v.form.designation.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('user-check-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.designation.$model,
      expression: "$v.form.designation.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    staticStyle: {
      "border-bottom-right-radius": "0",
      "border-top-right-radius": "0"
    },
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.designation, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeDesignation]
    }
  }, _vm._l(_vm.designations, function (designation) {
    return _c('option', {
      key: designation,
      domProps: {
        "value": designation
      }
    }, [_vm._v(" " + _vm._s(designation) + " ")]);
  }), 0)]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.fullname.$model,
      expression: "$v.form.fullname.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('fullname'),
      "name": "fullname"
    },
    domProps: {
      "value": _vm.$v.form.fullname.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.fullname, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), !_vm.$v.form.fullname.minLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name-atleast")) + " " + _vm._s(_vm.$v.form.fullname.$params.minLength.min) + " " + _vm._s(_vm.$t("validation.characters")) + " ")]) : _vm._e(), !_vm.$v.form.fullname.maxLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name-max")) + " " + _vm._s(_vm.$v.form.fullname.$params.maxLength.max) + " " + _vm._s(_vm.$t("validation.characters")) + " ")]) : _vm._e(), _vm.$v.form.fullname.$error && !_vm.$v.form.fullname.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.ic_number.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('user-check-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('the-mask', {
    staticClass: "form-control pl-5",
    attrs: {
      "id": "ic_number",
      "mask": ['######-##-####'],
      "placeholder": _vm.$t('mykad')
    },
    model: {
      value: _vm.$v.form.ic_number.$model,
      callback: function ($$v) {
        _vm.$set(_vm.$v.form.ic_number, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.form.ic_number.$model"
    }
  }), !_vm.$v.form.ic_number.minLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad-should")) + " " + _vm._s(_vm.$v.form.ic_number.$params.minLength.min) + " " + _vm._s(_vm.$t("validation.character-wo")) + " ")]) : _vm._e(), !_vm.$v.form.ic_number.maxLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad-should")) + " " + _vm._s(_vm.$v.form.ic_number.$params.maxLength.max) + " " + _vm._s(_vm.$t("validation.character-wo")) + " ")]) : _vm._e(), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad")) + " ")]) : _vm._e(), _vm.$v.form.ic_number.$error && !_vm.$v.form.ic_number.numeric ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mykad-no")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.notel.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mobile-no")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('user-check-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.notel.$model,
      expression: "$v.form.notel.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('mobile-no'),
      "name": "notel"
    },
    domProps: {
      "value": _vm.$v.form.notel.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.notel, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.notel.$error && !_vm.$v.form.notel.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-no")) + " ")]) : _vm._e(), _vm.$v.form.notel.$error && !_vm.$v.form.notel.numeric ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-in-number")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("username")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('user-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.ic_number,
      expression: "form.ic_number"
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "readonly": "",
      "type": "text",
      "name": "username",
      "placeholder": _vm.$t('username')
    },
    domProps: {
      "value": _vm.form.ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "ic_number", $event.target.value);
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("email")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('mail-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "type": "email",
      "placeholder": _vm.$t('email'),
      "name": "email"
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.password.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("password")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('key-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.password.$model,
      expression: "$v.form.password.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "type": "password",
      "placeholder": _vm.$t('password')
    },
    domProps: {
      "value": _vm.$v.form.password.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.password, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.password.minLength ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.pw-atleast")) + " " + _vm._s(_vm.$v.form.password.$params.minLength.min) + " " + _vm._s(_vm.$t("validation.characters")) + " ")]) : _vm._e(), !_vm.$v.form.password.passwordRegex ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.pw-must-contain")) + " ")]) : _vm._e(), _vm.$v.form.password.$error && !_vm.$v.form.password.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.password")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.confirm_password.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("confirm-password")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('key-icon', {
    staticClass: "fea icon-sm icons"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.confirm_password,
      expression: "form.confirm_password"
    }],
    staticClass: "form-control pl-5",
    attrs: {
      "type": "password",
      "placeholder": _vm.$t('confirm-password')
    },
    domProps: {
      "value": _vm.form.confirm_password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "confirm_password", $event.target.value);
      }
    }
  }), !_vm.$v.form.confirm_password.sameAsPassword ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.repeat-pw-same")) + " ")]) : _vm._e(), _vm.$v.form.confirm_password.$error && !_vm.$v.form.confirm_password.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.repeat-password")) + " ")]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.agreement,
      expression: "form.agreement"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1",
      "value": "1"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.agreement) ? _vm._i(_vm.form.agreement, "1") > -1 : _vm.form.agreement
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.agreement,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = "1",
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "agreement", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "agreement", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "agreement", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("signup.agree1")) + " "), _c('a', {
    staticClass: "text-primary",
    staticStyle: {
      "color": "#2f55d4 !important"
    },
    attrs: {
      "href": "https://awarisgroup.com/wp-content/uploads/2021/01/Terms-Conditions.pdf"
    }
  }, [_vm._v(_vm._s(_vm.$t("signup.agree2")))])])])])]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "readonly": !_vm.awarisOrganization
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("register")) + " "), _vm.isRegister ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])]), _c('div', {
    staticClass: "mx-auto"
  }, [_c('p', {
    staticClass: "mb-0 mt-3"
  }, [_c('small', {
    staticClass: "text-dark mr-2"
  }, [_vm._v(_vm._s(_vm.$t("signup.account")))]), _c('router-link', {
    staticClass: "text-dark font-weight-bold",
    attrs: {
      "to": "/login"
    }
  }, [_vm._v(_vm._s(_vm.$t("log-in")))])], 1)])]), _c('div', {
    staticClass: "row card-signup"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "text-sm-center"
  }, [_c('p', {
    staticClass: "mb-0 text-center"
  }, [_c('strong', [_vm._v("©2021 AWARIS. " + _vm._s(_vm.$t("copyright")) + ".")]), _vm._v(" " + _vm._s(_vm.$t("poweredby")) + " "), _c('strong', [_vm._v("PROOFFICE")])])])])])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }